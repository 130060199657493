<!--<template>
<div class="ktz-homePrincipal ktz-section p-flex-column p-ai-center">
     <div class="ktz-img p-d-flex p-ai-center p-flex-column p-mt-2">
        <img src="../../../assets/logo.png" alt="teste" />
    </div>
     <span class="ktz-desconto p-mt-2">SEMANA</span>
     <span class="ktz-desconto">DA MULHER</span>
     <div v-if="!comecou" class="p-d-flex ktz-timer p-px-6 p-jc-center p-flex-column p-mt-4">
      <span  style=" font-size: 21.5px; margin-top: -12px" class="ktz-data p-mb-2">De 20 a 24 de Novembro</span>
       <span class="ktz-desconto">Faltam: </span>
       <div class="p-d-flex p-jc-center p-mt-2">
          <div class="ktz-dias p-d-flex p-flex-column p-ml-3">
            <div class="ktz-timer-container p-p-2" ref="dias">00</div>
            <div class="ktz-timer-text p-mt-2">Dias</div>
          </div>
          <div class="ktz-horas p-d-flex p-flex-column p-mx-2">
            <div class="ktz-timer-container p-p-2" ref="horas">00</div>
            <div class="ktz-timer-text p-mt-2">Horas</div>
          </div>
          <div class="ktz-minutos p-d-flex p-flex-column p-mx-2">
            <div class="ktz-timer-container p-p-2" ref="minutos">00</div>
            <div class="ktz-timer-text p-mt-2">Minutos</div>
          </div>
          <div class="ktz-segundos p-d-flex p-flex-column p-mr-3">
            <div class="ktz-timer-container p-p-2" ref="segundos">00</div>
            <div class="ktz-timer-text p-mt-2">Segundos</div>
          </div>
       </div>
     </div>
     <div v-if="true" style="font-weight: 500" class="p-d-flex p-mt-4"> 
        Clique no botão e fale com a vendedora!
     </div>
     <div v-else style="font-weight: 500" class="p-d-flex p-mt-2"> 
       Todo o catálogo feminino com descontos exclusivos.
     </div>
     <div style="width: 100%" class="p-px-3 p-d-flex">
        <Button @click="selecionarGrupos" class="ktz-button p-button-rounded p-mt-4 animate__animated animate__pulse animate__infinite	infinite">
          <span style="background: transparent; color: #FFF" v-if="true">FALE PELO WHATSAPP!</span>
          <span style="background: transparent; color: #FFF" v-else>VEJA NOSSAS OFERTAS!</span>
        </Button>
     </div>
     <div style="font-weight: 500" class="p-d-flex p-mt-4 p-mb-4"> 
       Todo o catálogo feminino com descontos exclusivos.
     </div>
     <div class="rodape p-flex">
        ©️ Traje Country, 2023. Todos Os Direitos Reservados CNPJ 37.740.673/0001-98 Aparecida de Goiânia - Goiás
      </div>
     <div style="font-size: 18px" class="p-d-flex p-mt-4 p-mb-4"> 
       
     </div>
  </div>
</template>-->
<template>
  <div class="ktz-homePrincipal ktz-section p-flex-column p-ai-center">
     <div class="ktz-img p-d-flex p-ai-center p-flex-column p-mt-2">
        <img src="../../../assets/logo.png" alt="teste" />
    </div>
     <span class="ktz-desconto p-mb-2" style=" font-size: 25px;">Traje Country</span>
     <span v-if="comecou" class="p-mb-4" style="font-size: 14px;">A ÚNICA BLACK DE VERDADE!</span>

     <div v-if="!comecou" class="p-d-flex ktz-timer p-px-6 p-jc-center p-flex-column p-mt-4">
      <span  style=" font-size: 21.5px; margin-top: -12px; color: #E4191C" class="ktz-data p-mb-2">De 20 a 24 de Novembro</span>
      <span class="ktz-desconto" style="font-size: 18px;">A ÚNICA BLACK DE VERDADE!</span>
       <span class="ktz-desconto p-mt-4" style=" font-size: 21.5px;">Faltam: </span>
       <div class="p-d-flex p-jc-center p-mt-2">
          <div class="ktz-dias p-d-flex p-flex-column p-ml-3">
            <div class="ktz-timer-container p-p-2" ref="dias">00</div>
            <div class="ktz-timer-text p-mt-2">Dias</div>
          </div>
          <div class="ktz-horas p-d-flex p-flex-column p-mx-2">
            <div class="ktz-timer-container p-p-2" ref="horas">00</div>
            <div class="ktz-timer-text p-mt-2">Horas</div>
          </div>
          <div class="ktz-minutos p-d-flex p-flex-column p-mx-2">
            <div class="ktz-timer-container p-p-2" ref="minutos">00</div>
            <div class="ktz-timer-text p-mt-2">Minutos</div>
          </div>
          <div class="ktz-segundos p-d-flex p-flex-column p-mr-3">
            <div class="ktz-timer-container p-p-2" ref="segundos">00</div>
            <div class="ktz-timer-text p-mt-2">Segundos</div>
          </div>
       </div>
     </div>
     <!-- <div v-else class="p-d-flex ktz-timer p-px-2 p-jc-center p-flex-column p-mt-5">
      <span  style=" font-size: 21.5px; margin-top: -12px" class="ktz-data p-mb-2">Pedido mínimo de 15 peças</span>
     </div> -->
     <div v-if="!comecou" style="font-weight: 500" class="p-d-flex p-mt-4"> 
        Clique no botão e entre no grupo vip!
     </div>
      <div v-if="comecou" style="font-weight: 500" class="p-d-flex p-mt-2"> 
        Clique no botão e fale com a vendedora.
     </div>
     <div style="width: 100%" class="p-px-3 p-d-flex">
        <Button @click="selecionarGrupos" class="ktz-button p-button-rounded p-mt-4 animate__animated animate__pulse animate__infinite	infinite">
          <span style="background: transparent; color: #FFF" v-if="!comecou">ENTRE NO GRUPO VIP!</span>
          <span style="background: transparent; color: #FFF" v-else>FALAR NO WHATSAPP</span>
        </Button>
     </div>
      <div v-if="!comecou" style="font-weight: 500;" class="p-d-flex p-mt-4"> 
        Descontos exclusivos para quem estiver no grupo.
     </div>
      <div v-if="comecou" style="font-weight: 500" class="p-d-flex p-mt-4 p-mb-4"> 
        Todo o catálogo com descontos exclusivos.
     </div>
     <div class="rodape p-flex">
        ©️ Traje Country, 2023. Todos Os Direitos Reservados CNPJ 37.740.673/0001-98 Aparecida de Goiânia - Goiás
      </div>
  </div>
</template>

<script>
import Button from 'primevue/button';
import { initializeApp } from 'firebase/app';
import { getDatabase, ref,child, get, set } from "firebase/database";

export default {
  components: {
    Button
  },
  data() {
    return {
      grupos: null,
      comecouBlackFriday: false
    }
  },
  mounted() {
    this.initFirebase();
    this.initCountdown();
  },
  computed: {
    comecou: {
      set(valor) {
        this.comecouBlackFriday = valor;
      },
      get() {
        return this.comecouBlackFriday;
      }
    }
  },
  methods: {
    async initFirebase(){
      initializeApp({
        apiKey: "AIzaSyAg_qT_YFco5OJwepTV6e9txKvN1xGBFGY",
        authDomain: "traje-black-friday.firebaseapp.com",
        databaseURL: "https://traje-black-friday-default-rtdb.firebaseio.com",
        projectId: "traje-black-friday",
        storageBucket: "traje-black-friday.appspot.com",
        messagingSenderId: "960681107679",
        appId: "1:960681107679:web:ba53663da3089f827d096a",
        measurementId: "G-BJCPN2802J"
      })

      this.grupos = await this.carregarDadosGrupos();
    },
    carregarDadosGrupos(){
      return new Promise((resolve, reject) => {
        const dbRef = ref(getDatabase())
        get(child(dbRef, '/')).then(result => {
          console.log(result.val())
          resolve(result.val());
        })
        .catch(e => reject(e));
      });
    },
    initCountdown() {
      const ref = this;
      var countDownDate = new Date("Nov 20, 2023 00:01:00").getTime();
      var intervalo = setInterval(function() {
        var now = new Date().getTime();
        var distance = countDownDate - now;
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);
        ref.$refs.dias.innerText = days < 10 ? '0' + days : days;
        ref.$refs.horas.innerText = hours < 10 ? '0' + hours : hours;
        ref.$refs.minutos.innerText = minutes < 10 ? '0' + minutes : minutes;
        ref.$refs.segundos.innerText = seconds < 10 ? '0' + seconds : seconds;
        if (distance < 0) {
          clearInterval(intervalo);
          ref.comecou = true;
        }
      }, 1000);
    },
    selecionarGrupos() {
      window.fbq('trackCustom', 'Contato_WhatsApp');
    
      if(!this.comecou){
        window.location.href = 'https://chat.whatsapp.com/Klphw2j6USJKdBFOfHobed';
      } else {
        window.location.href = 'https://api.whatsapp.com/send?phone=5562982777959&text=Ol%C3%A1!%20Gostaria%20de%20ver%20as%20ofertas%20de%20Black%20Friday';
      }
    },
    atualizarDadosDoGrupo() {
      const dbRef = ref(getDatabase(), '/');
      set(dbRef, this.grupos)
    }
  }
}
</script>

<style scoped>

div::-webkit-scrollbar {
  display: none;
}
.ktz-homePrincipal {
  text-align: center;
  max-width: 620px;
}
.gallery-cell {
   height: 40px;
   border: 1px solid blue;
}

.gallery-cell > img{
    width: 100%;
    max-width: 320px;
    box-shadow: 0 7px 8px -4px rgb(0 0 0 / 20%), 0 13px 19px 2px rgb(0 0 0 / 14%), 0 5px 24px 4px rgb(0 0 0 / 12%);
}

@media screen and ( min-width: 768px ) {
/* half-width cells for larger devices */
    .gallery-cell { width: 50%; }
}
.ktz-titulo {
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: 6px;
  font-size: 1.6rem;
  color: #000;
}

.ktz-desconto {
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 700;
  
}

.ktz-data {
  font-weight: 600;
  font-size: 1.5rem;
}
.ktz-timer {
  width: 100%;
  font-size: 1.8rem;
  font-weight: 600;
}

.ktz-timer-text {
  font-size: 0.8rem;
  font-weight: 400;
}

.ktz-timer-container {
  border: 2px solid #E4191C;
  width: 80px;
}

.rodape {
    width: 100%;
    height: 40px;
    margin-top: auto;
    font-size: 10px;
    padding: 10px;
    text-align: center;
    letter-spacing: 1px;
    color: #000;
    background: transparent;
    border-top: 1px solid black;
}

@media (min-width: 320px) and (max-width: 374px)
{
    .rodape {
        font-size: 14px!important;
    }
    .avaliacao {
        font-size: 16px;
    }
}
</style>