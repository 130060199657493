<template>
  <div style="overflow: hidden;" class="p-d-flex p-flex-column p-jc-center p-ai-center">
      <ktz-home-principal />
  </div>
</template>

<script>
import HomePrincipal from './Widgets/HomePrincipal.vue';
export default {
    components: {
      'ktz-home-principal': HomePrincipal
    }
}
</script>

<style>
#app {
   width: 100vw;
   height: 100%;
   overflow-y: scroll;
   overflow-x: hidden;
}

.ktz-section {
    display: flex;
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
}

.ktz-img {
  width: 100%;
}

.ktz-img img {
  width: 250px;
}

.ktz-button {
  width: 100%;
  height: 70px;
  font-weight: 700;
  color:#FFF!important;
}

.p-button {
  background: #25d366; 
  border-color: #25d366;
  /*background: #E4191C;
  border-color: #E4191C;*/
  color: #FFF!important;
  border-radius: 10px;
}

.p-button .p-text{
  color: #FFF!important;
}

.p-button:enabled:active {
  background: #25d366;
  border-color: #25d366;
  /*background: #FF1D8E;
  border-color: #FF9CCE;*/
  color: #FFF;
}
.p-button:enabled:hover {
  background: #075e54;
  border-color: #25d366;
  /*background: #FF1D8E;
  border-color: #FF9CCE;*/
  color: #FFF;
}
.p-button:focus {
  box-shadow: 0 0 0 0.2rem white;
  color: #FFF;
}
</style>