<template>
  <ktz-home />
</template>

<script>
import Home from './views/Home/Home.vue'

export default {
  name: 'App',
  components: {
    'ktz-home': Home
  }
}
</script>

<style>
* {
  padding: 0;
  margin: 0;
  background: #000;
  color: #fff;
  font-family: 'Montserrat', sans-serif;
}
</style>
